import { createRouter, createWebHistory } from 'vue-router'

export const routes = [
  {
    name: 'minesweeper',
    path: '/',
    component: () => import('../components/MineSweeper/Game.vue')
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
