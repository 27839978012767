import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import VueProgressBar from "@aacassandra/vue3-progressbar";
import axios from 'axios';

const options = {
    color: "red",
    failedColor: "#874b4b",
    thickness: "5px",
    transition: {
        speed: "0.2s",
        opacity: "0.9s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
};


window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

createApp(App).use(VueProgressBar, options).use(router).mount('#app')
